<template>
  <div>
    <img src="@/assets/img/common/404.png" alt="" />
    <!-- <button @click="toHome">返回首页</button> -->
  </div>
</template>

<script>
export default {
  name: "Exception404",
  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
  },
};
</script>
<style scoped>
h2 {
  width: 100%;
  text-align: center;
  margin: 80px auto;
}
div {
  width: 393px;
  height: 172px;
  margin: 120px auto;
}
img {
  width: 100%;
}
</style>
